import { UserBlocked, UserBlockedDto } from '@app/store/models';
import { CustomerDto } from './index';

export class Customer {
    no: string;
    name: string;
    secondName: string;
    balance: string;
    balanceDue: string;
    creditLimitLcy: string;
    salesPersonCode: string;
    locationCode: string;
    blocked: UserBlocked | null;
    vatRegistrationNo: string;
    postCode: string;
    city: string;
    searchName: string;
    addresses: string;
    paymentTerm: string;
    paymentMethod: string;

    constructor(customerDto: CustomerDto) {
        this.no = customerDto.no;
        this.name = customerDto.name ?? '';
        this.secondName = customerDto.name2 ?? '';
        this.balance = customerDto.balance;
        this.balanceDue = customerDto.balance_due;
        this.creditLimitLcy = customerDto.credit_limit_lcy;
        this.salesPersonCode = customerDto.sales_person_code;
        this.locationCode = customerDto.location_code;
        this.blocked = this.getBlockedValue(customerDto.blocked);
        this.vatRegistrationNo = customerDto.vat_registration_no;
        this.postCode = customerDto.post_code;
        this.city = customerDto.city;
        this.searchName = customerDto.search_name;
        this.addresses = customerDto.addresses;
        this.paymentTerm = customerDto.payment_terms;
        this.paymentMethod = customerDto.payment_method;
    }

    getBlockedValue(blocked: UserBlockedDto) {
        switch (blocked) {
            case 'All':
                return 'All';
            case 'Ship':
                return 'Ship';
            case 'Invoice':
                return 'Invoice';
            default:
                return null;
        }
    }
}
